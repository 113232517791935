<template>
  <validation-observer class="validation-observer" v-slot="{ errors, invalid }">
    <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
      <div
        class="print-address-sheet-popup"
        @keyup.esc.stop="cancel"
        tabindex="-1"
      >
        <base-loading :waitFlg="waitFlg" />
        <popup
          :buttons="['閉じる', '印刷']"
          :disabledFlg="
            invalid ||
              invalidAddressSheetWidth ||
              invalidAddressSheetHeight ||
              waitFlg ||
              lookOnlyFlg === 1
          "
          @cancel="cancel"
          @decision="makePdf"
          @click-close-mark="cancel"
        >
          <template v-slot:content>
            <div class="content">
              <div class="title">宛名シートの印刷</div>
              <div class="form">
                <div class="form-item">
                  <div class="label">用紙サイズ</div>
                  <div class="radio-buttons input">
                    <base-radio-button
                      v-for="datum in paperSizeRadioButtonData"
                      :key="'paper-size' + datum.id"
                      :option="datum"
                      :styles="
                        datum.id !== -4 ? { width: '60px' } : { width: '80px' }
                      "
                      v-model="printOptions.paperSize"
                    />
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">印刷の向き</div>
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in addressLayoutData"
                      :key="'address-layout' + datum.id"
                      :option="datum"
                      :styles="{ width: '60px' }"
                      :disabled="printOptions.paperSize === 'PC'"
                      v-model="printOptions.verticalFlg"
                    />
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">レイアウト</div>
                  <div class="two-values">
                    <select-box-form
                      :selectData="selectRowNumber"
                      :styles="{ width: '60px' }"
                      :disabled="printOptions.paperSize === 'PC'"
                      v-model="printOptions.rows"
                      >行数</select-box-form
                    >
                    <select-box-form
                      :selectData="selectColumnNumber"
                      :styles="{ width: '60px' }"
                      :disabled="printOptions.paperSize === 'PC'"
                      v-model="printOptions.columns"
                      >列数</select-box-form
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">余白（mm）</div>
                  <div class="two-values">
                    <text-input-form
                      data-test="input top-bottom-margins"
                      :vid="'topBottomMargins'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize === 'PC'"
                      v-model.number="printOptions.topBottomMargins"
                      @blur="blur('topBottomMargins')"
                      >上下余白</text-input-form
                    >
                    <text-input-form
                      data-test="input left-right-margins"
                      :vid="'leftRightMargins'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize === 'PC'"
                      v-model.number="printOptions.leftRightMargins"
                      @blur="blur('leftRightMargins')"
                      >左右余白</text-input-form
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">間隔（mm）</div>
                  <div class="two-values">
                    <text-input-form
                      data-test="input vertical-margins"
                      :vid="'verticalMargins'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize === 'PC'"
                      v-model.number="printOptions.verticalMargins"
                      @blur="blur('verticalMargins')"
                      >行間隔</text-input-form
                    >
                    <text-input-form
                      data-test="input horizontal-margins"
                      :vid="'horizontalMargins'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize === 'PC'"
                      v-model.number="printOptions.horizontalMargins"
                      @blur="blur('horizontalMargins')"
                      >列間隔</text-input-form
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">はがきでの飼主の郵便番号の位置（mm）</div>
                  <div class="two-values">
                    <text-input-form
                      data-test="input post-card-owner-postal-code-vertical-position"
                      :vid="'postCardOwnerPostalCodeVerticalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize !== 'PC'"
                      v-model.number="
                        printOptions.postCardOwnerPostalCodeVerticalPosition
                      "
                      @blur="blur('postCardOwnerPostalCodeVerticalPosition')"
                      >上</text-input-form
                    >
                    <text-input-form
                      data-test="input post-card-owner-postal-code-horizontal-position"
                      :vid="'postCardOwnerPostalCodeHorizontalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize !== 'PC'"
                      v-model.number="
                        printOptions.postCardOwnerPostalCodeHorizontalPosition
                      "
                      @blur="blur('postCardOwnerPostalCodeHorizontalPosition')"
                      >左</text-input-form
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">はがきでの飼主住所の位置（mm）</div>
                  <div class="two-values">
                    <text-input-form
                      data-test="input post-card-owner-address-vertical-position"
                      :vid="'postCardOwnerAddressVerticalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize !== 'PC'"
                      v-model.number="
                        printOptions.postCardOwnerAddressVerticalPosition
                      "
                      @blur="blur('postCardOwnerAddressVerticalPosition')"
                      >上</text-input-form
                    >
                    <text-input-form
                      data-test="input post-card-owner-address-horizontal-position"
                      :vid="'postCardOwnerAddressHorizontalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize !== 'PC'"
                      v-model.number="
                        printOptions.postCardOwnerAddressHorizontalPosition
                      "
                      @blur="blur('postCardOwnerAddressHorizontalPosition')"
                      >左</text-input-form
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">はがきでの飼主名の位置（mm）</div>
                  <div class="two-values">
                    <text-input-form
                      data-test="input post-card-owner-name-vertical-position"
                      :vid="'postCardOwnerNameVerticalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize !== 'PC'"
                      v-model.number="
                        printOptions.postCardOwnerNameVerticalPosition
                      "
                      @blur="blur('postCardOwnerNameVerticalPosition')"
                      >上</text-input-form
                    >
                    <text-input-form
                      data-test="input post-card-owner-name-horizontal-position"
                      :vid="'postCardOwnerNameHorizontalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="printOptions.paperSize !== 'PC'"
                      v-model.number="
                        printOptions.postCardOwnerNameHorizontalPosition
                      "
                      @blur="blur('postCardOwnerNameHorizontalPosition')"
                      >左</text-input-form
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">はがきでの医院情報の位置（mm）</div>
                  <div class="two-values">
                    <text-input-form
                      data-test="input post-card-sender-vertical-position"
                      :vid="'postCardSenderVerticalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="
                        printOptions.paperSize !== 'PC' ||
                          !printOptions.postCardSenderFlg
                      "
                      v-model.number="
                        printOptions.postCardSenderVerticalPosition
                      "
                      @blur="blur('postCardSenderVerticalPosition')"
                      >上</text-input-form
                    >
                    <text-input-form
                      data-test="input post-card-sender-horizontal-position"
                      :vid="'postCardSenderHorizontalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="
                        printOptions.paperSize !== 'PC' ||
                          !printOptions.postCardSenderFlg
                      "
                      v-model.number="
                        printOptions.postCardSenderHorizontalPosition
                      "
                      @blur="blur('postCardSenderHorizontalPosition')"
                      >左</text-input-form
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div class="label">はがきでの医院の郵便番号の位置（mm）</div>
                  <div class="two-values">
                    <text-input-form
                      data-test="input post-card-sender-postal-code-vertical-position"
                      :vid="'postCardSenderPostalCodeVerticalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="
                        printOptions.paperSize !== 'PC' ||
                          !printOptions.postCardSenderFlg
                      "
                      v-model.number="
                        printOptions.postCardSenderPostalCodeVerticalPosition
                      "
                      @blur="blur('postCardSenderPostalCodeVerticalPosition')"
                      >上</text-input-form
                    >
                    <text-input-form
                      data-test="input post-card-sender-postal-code-horizontal-position"
                      :vid="'postCardSenderPostalCodeHorizontalPosition'"
                      :maxlength="3"
                      :validationRules="{ requiredRule: true, intRule: true }"
                      :styles="{ width: '60px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                      :disabled="
                        printOptions.paperSize !== 'PC' ||
                          !printOptions.postCardSenderFlg
                      "
                      v-model.number="
                        printOptions.postCardSenderPostalCodeHorizontalPosition
                      "
                      @blur="blur('postCardSenderPostalCodeHorizontalPosition')"
                      >左</text-input-form
                    >
                  </div>
                </div>
                <div class="post-card-sender-check">
                  <base-check-box
                    :isChecked="printOptions.postCardSenderFlg"
                    :labelText="'はがきでの医院情報を印刷する'"
                    :disabled="printOptions.paperSize !== 'PC'"
                    v-model="printOptions.postCardSenderFlg"
                  />
                </div>
              </div>
              <div v-if="getSizeErrorMessage(errors) !== ''" class="size-error">
                {{ getSizeErrorMessage(errors) }}
              </div>
              <slot name="registrationCheck" />
            </div>
          </template>
        </popup>
      </div>
    </focus-trap>
    <announce-popup
      v-if="popupFlg"
      v-bind="popup"
      @close="closePopup"
      @cancel="popupFlg = false"
      @decision="$emit('close')"
      >{{ popup.message }}</announce-popup
    >
    <slot name="announcePopup" />
  </validation-observer>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import Popup from '@/components/popups/Popup'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { printAddressSheet } from '@/utils/print_address_sheet'
import { PAPER_SIZES } from '@/utils/define'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import _ from 'lodash'

export default {
  name: 'PrintAddressSheetPopup',

  components: {
    BaseLoading,
    Popup,
    BaseRadioButton,
    SelectBoxForm,
    TextInputForm,
    BaseCheckBox,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver
  },

  mixins: [CheckPopupInputDifference],

  props: {
    ownerIds: { type: Array },
    patientIds: { type: Array },
    waitFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      printOptions: {},
      initialPrintOptions: {},
      cellWidth: undefined,
      cellHeight: undefined,
      paperSizeRadioButtonData: [
        { id: -1, eachValue: 'A4', labelName: 'A4' },
        { id: -2, eachValue: 'B5', labelName: 'B5' },
        { id: -3, eachValue: 'A5', labelName: 'A5' },
        { id: -4, eachValue: 'PC', labelName: 'はがき' }
      ],
      addressLayoutData: [
        { id: -5, eachValue: 1, labelName: '縦' },
        { id: -6, eachValue: 0, labelName: '横' }
      ],
      owners: [],
      patients: [],
      popupFlg: false,
      popup: {
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる'],
        layerNumber: 2
      }
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getOwnerById: 'owners/getDataById',
      getPatientById: 'patients/getDataById',
      prefectures: 'master/getPrefectures',
      printSetting: 'printSetting/getData'
    }),
    selectRowNumber() {
      return [...Array(11).keys()].map(i => {
        return { id: i + 1, name: i + 1 }
      })
    },
    selectColumnNumber() {
      return [...Array(6).keys()].map(i => {
        return { id: i + 1, name: i + 1 }
      })
    },
    minCellWidth() {
      return 48.3
    },
    minCellHeight() {
      const patientsMap = this.makePatientsMap()
      let maxLength = 0
      for (let patients of patientsMap.values()) {
        if (patients.length > maxLength) {
          maxLength = patients.length
        }
      }
      // 高さ210mmで40行入力できたので1行あたり5.25mmとした。
      return 5.25 * (4 + maxLength)
    },
    invalidAddressSheetWidth() {
      return this.cellWidth < this.minCellWidth
    },
    invalidAddressSheetHeight() {
      return this.cellHeight < this.minCellHeight
    },
    diffFlg() {
      return !_.isEqual(this.initialPrintOptions, this.printOptions)
    }
  },

  watch: {
    printOptions: {
      deep: true,
      handler() {
        const { paperSize } = this.printOptions
        const {
          verticalFlg,
          rows,
          columns,
          topBottomMargins,
          leftRightMargins,
          verticalMargins,
          horizontalMargins
        } =
          paperSize !== 'PC'
            ? this.printOptions
            : {
                verticalFlg: 1,
                rows: 1,
                columns: 1,
                topBottomMargins: 5,
                leftRightMargins: 5,
                verticalMargins: 0,
                horizontalMargins: 0
              }
        const paperWidth = verticalFlg
          ? PAPER_SIZES[paperSize]?.x
          : PAPER_SIZES[paperSize]?.y
        const paperHeight = verticalFlg
          ? PAPER_SIZES[paperSize]?.y
          : PAPER_SIZES[paperSize]?.x
        const availableWidth =
          paperWidth - leftRightMargins * 2 - horizontalMargins * (columns - 1)
        const availableHeight =
          paperHeight - topBottomMargins * 2 - verticalMargins * (rows - 1)
        this.cellWidth = availableWidth / columns
        this.cellHeight = availableHeight / rows
      }
    }
  },

  created() {
    this.printOptions = {
      paperSize: this.printSetting.addressSheetPaperSize,
      verticalFlg: this.printSetting.addressSheetVerticalFlg,
      rows: this.printSetting.addressSheetRows,
      columns: this.printSetting.addressSheetColumns,
      topBottomMargins: this.printSetting.addressSheetTopBottomMargins,
      leftRightMargins: this.printSetting.addressSheetLeftRightMargins,
      verticalMargins: this.printSetting.addressSheetVerticalMargins,
      horizontalMargins: this.printSetting.addressSheetHorizontalMargins,
      postCardSenderFlg: this.printSetting.postCardSenderFlg ? true : false,
      postCardOwnerPostalCodeVerticalPosition: this.printSetting
        .postCardOwnerPostalCodeVerticalPosition,
      postCardOwnerPostalCodeHorizontalPosition: this.printSetting
        .postCardOwnerPostalCodeHorizontalPosition,
      postCardOwnerNameVerticalPosition: this.printSetting
        .postCardOwnerNameVerticalPosition,
      postCardOwnerNameHorizontalPosition: this.printSetting
        .postCardOwnerNameHorizontalPosition,
      postCardOwnerAddressVerticalPosition: this.printSetting
        .postCardOwnerAddressVerticalPosition,
      postCardOwnerAddressHorizontalPosition: this.printSetting
        .postCardOwnerAddressHorizontalPosition,
      postCardSenderVerticalPosition: this.printSetting
        .postCardSenderVerticalPosition,
      postCardSenderHorizontalPosition: this.printSetting
        .postCardSenderHorizontalPosition,
      postCardSenderPostalCodeVerticalPosition: this.printSetting
        .postCardSenderPostalCodeVerticalPosition,
      postCardSenderPostalCodeHorizontalPosition: this.printSetting
        .postCardSenderPostalCodeHorizontalPosition
    }
    this.initialPrintOptions = _.cloneDeep(this.printOptions)

    for (let i in this.ownerIds) {
      this.owners.push(this.getOwnerById(this.ownerIds[i]))
    }

    for (let i in this.patientIds) {
      this.patients.push(this.getPatientById(this.patientIds[i]))
    }
  },

  methods: {
    getSizeErrorMessage(errors) {
      const postCardFlg = this.printSetting.addressSheetPaperSize === 'PC'
      const makeMessage = (textA, textB) =>
        `※${textA}から想定される宛名シートの${textB}が「用紙サイズ」の${textB}を超えています。「用紙サイズ」の${textB}におさまるよう値を変更してください。`
      if (this.showSheetWidthError(errors)) {
        return postCardFlg
          ? makeMessage('「左右余白」', '横幅')
          : makeMessage('「印刷の向き」「列数」「左右余白」「列間隔」', '横幅')
      }
      if (this.showSheetHeightError(errors)) {
        return postCardFlg
          ? makeMessage('「上下余白」', '縦幅')
          : makeMessage('「印刷の向き」「行数」「上下余白」「行間隔」', '縦幅')
      }
      return ''
    },
    showSheetWidthError(errors) {
      return !this.hasTextInputError(errors) && this.invalidAddressSheetWidth
    },
    showSheetHeightError(errors) {
      return (
        !this.hasTextInputError(errors) &&
        !this.invalidAddressSheetWidth &&
        this.invalidAddressSheetHeight
      )
    },
    hasTextInputError(errors) {
      const vids = [
        'topBottomMargins',
        'leftRightMargins',
        'verticalMargins',
        'horizontalMargins'
      ]
      return vids.some(vid => errors[vid]?.length > 0)
    },
    cancel() {
      if (!this.diffFlg) {
        this.$emit('close')
      } else {
        this.popupFlg = true
        this.popup.type = 'alert'
        this.popup.title = '確認'
        this.popup.message = `閉じようとしています。よろしいですか？`
        this.popup.buttons = ['キャンセル', '閉じる']
      }
    },
    blur(inputName) {
      if (this.printOptions[inputName] === '') this.printOptions[inputName] = 0
    },
    makePatientsMap() {
      const patientsMap = new Map()
      for (let patient of this.patients) {
        if (!patientsMap.has(patient.ownerId)) {
          patientsMap.set(patient.ownerId, [])
        }
        patientsMap.get(patient.ownerId).push(patient)
      }
      return patientsMap
    },
    async makePdf() {
      const patientsMap = this.makePatientsMap()
      const basePrintOptions = this.printOptions
      const printOptions =
        this.printOptions.paperSize === 'PC'
          ? {
              ...basePrintOptions,
              verticalFlg: 1,
              rows: 1,
              columns: 1,
              topBottomMargins: 5,
              leftRightMargins: 5,
              verticalMargins: 0,
              horizontalMargins: 0
            }
          : { ...basePrintOptions }
      const baseArgs = {
        printOptions: printOptions,
        cellWidth: this.cellWidth,
        cellHeight: this.cellHeight,
        owners: this.owners,
        patientsMap,
        prefectures: this.prefectures
      }
      const args =
        this.printOptions.paperSize === 'PC'
          ? {
              ...baseArgs,
              clinic: this.clinic
            }
          : { ...baseArgs }
      printAddressSheet(args)
      this.$emit('finish-make-pdf')
    },
    closePopup() {
      this.popupFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.print-address-sheet-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    min-width: 560px;
    font-size: 15px;
    text-align: left;
    position: relative;
    > .title {
      font-size: 16px;
      font-weight: bold;
    }
    > .form {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      > .form-item {
        display: flex;
        align-items: center;
        min-height: 33px;
        > .label {
          width: 300px;
          text-align: left;
        }
        > .radio-buttons {
          display: flex;
        }
        > .two-values {
          display: flex;
          width: 380px;
          > * {
            flex: 1;
          }
          ::v-deep .error {
            width: 100%;
            white-space: nowrap;
          }
        }
      }
    }
    > .size-error {
      margin-top: 2px;
      position: absolute;
      line-height: 13px;
      color: #{$tomato};
      font-size: 12px;
      padding-left: 1em;
      text-indent: -1em;
    }
    > .post-card-sender-check {
      margin-top: 32px;
      display: inline-block;
    }
  }
}
</style>

<template>
  <div class="vaccination-print-address-sheet-popup">
    <print-address-sheet-popup
      :ownerIds="ownerIds"
      :patientIds="patientIds"
      :waitFlg="waitFlg"
      @close="$emit('close')"
      @finish-make-pdf="finishMakePdf"
    >
      <template v-slot:registrationCheck>
        <div class="registration-check">
          <base-check-box
            :isChecked="createFlg"
            :labelText="'予防接種連絡（郵便）を登録'"
            v-model="createFlg"
          />
        </div>
      </template>
      <template v-slot:announcePopup>
        <announce-popup
          v-if="popupFlg"
          v-bind="popup"
          @close="closePopup"
          @cancel="popupFlg = false"
          @decision="$emit('close')"
          >{{ popup.message }}</announce-popup
        >
      </template>
    </print-address-sheet-popup>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import PrintAddressSheetPopup from '@/components/popups/PrintAddressSheetPopup'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'VaccinationPrintAddressSheetPopup',

  components: {
    AnnouncePopup,
    BaseCheckBox,
    PrintAddressSheetPopup
  },

  props: {
    reminderVaccinationSets: { type: Array }
  },

  data() {
    return {
      popupFlg: false,
      popup: {
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる'],
        layerNumber: 2
      },
      createFlg: false,
      updatedFlg: false,
      waitFlg: false
    }
  },

  computed: {
    owners() {
      const vaccinationOwners = this.reminderVaccinationSets.map(v => v.owner)
      const owners = Array.from(
        new Map(vaccinationOwners.map(owner => [owner.id, owner])).values()
      )
      return owners
    },
    ownerIds() {
      return this.owners.map(V => V.id)
    },
    patients() {
      return this.reminderVaccinationSets.map(v => v.patient)
    },
    patientIds() {
      return this.patients.map(V => V.id)
    }
  },

  methods: {
    async finishMakePdf() {
      if (this.createFlg) {
        await this.bulkCreateLetter()
      }
    },
    makeVaccineReminders() {
      const today = moment().format('YYYYMMDD')
      const vaccineReminders = this.reminderVaccinationSets.map(v => {
        const reminder = {
          patientId: v.patient.id,
          vaccineIds: v.vaccinations.map(v => v.vaccineId).join(','),
          date: today,
          media: 'letter',
          content: ''
        }
        return reminder
      })
      return vaccineReminders
    },
    async bulkCreateLetter() {
      this.waitFlg = true
      const vaccineReminders = this.makeVaccineReminders()
      const res = await this.$store.dispatch(
        'vaccineReminders/bulkCreateLetter',
        vaccineReminders
      )
      if (res.result === true) {
        this.popup.buttons = ['閉じる']
        if (res.errorPatientIds.length > 0) {
          const patients = this.reminderVaccinationSets.map(v => v.patient)
          const patientNames = res.errorPatientIds.map(patientId => {
            const patient = patients.find(v => v.id === patientId)
            return patient.name
          })
          this.popup.type = 'alert'
          this.popup.title = '注意'
          this.popup.message = `予防接種連絡を登録しました。\n但し、下記の患者の予防接種連絡登録には失敗しました。\n・${patientNames.join(
            '\n'
          )}\n既に削除された患者が使用されています。\nブラウザの更新をクリックし、最新の患者情報をご確認下さい。`
          this.updatedFlg = true
        } else if (res.vaccineReminders.length > 0) {
          this.popup.type = 'success'
          this.popup.title = '完了'
          this.popup.message = '予防接種連絡を登録しました'
          this.updatedFlg = true
        } else {
          this.waitFlg = false
          return
        }
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = '予防接種連絡の登録に失敗しました'
      }
      this.popupFlg = true
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      if (this.updatedFlg) {
        this.$emit('close')
        this.$emit('updated')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.registration-check {
  margin-top: 32px;
  display: inline-block;
}
</style>

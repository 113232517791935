<template>
  <div
    class="owners-list-table-body-row"
    :class="{
      'checkbox-mode': checkboxModeFlg,
      even: evenFlg,
      last: lastFlg
    }"
    data-e2e="owners-list-table-body-row"
  >
    <owners-list-table-row-item
      v-for="item in rowItems"
      :key="item.itemId"
      v-bind="item"
    >
      <base-check-box
        v-if="checkboxModeFlg && item.itemId === 1"
        :isChecked="targetOwnerFlg"
        :disabled="bulkSendMailModeFlg && ownerEmail === ''"
        @input="checkTargetOwnerFlg"
      />
      <div
        class="tags wrap"
        v-if="
          (checkboxModeFlg && item.itemId === 2 && ownerTags.length > 0) ||
            (!checkboxModeFlg && item.itemId === 1 && ownerTags.length > 0)
        "
      >
        <div v-for="tag in ownerTags" :key="tag.id">
          <base-tag-item :tag="tag" />
        </div>
      </div>
    </owners-list-table-row-item>
  </div>
</template>

<script>
import OwnersListTableRowItem from '@/components/parts/atoms/OwnersListTableRowItem.vue'
import BaseTagItem from '../atoms/BaseTagItem.vue'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'

export default {
  name: 'OwnersListTableBodyRow',

  components: {
    OwnersListTableRowItem,
    BaseTagItem,
    BaseCheckBox
  },

  props: {
    rowItems: { type: Array },
    ownerTags: { type: Array },
    ownerEmail: { type: String },
    targetOwnerFlg: { type: Boolean },
    bulkSendMailModeFlg: { type: Boolean, default: false },
    checkboxModeFlg: { type: Boolean, default: false },
    evenFlg: { type: Boolean, default: false },
    lastFlg: { type: Boolean, default: false }
  },

  methods: {
    checkTargetOwnerFlg(targetOwnerFlg) {
      this.$emit('check-target-owner-flg', targetOwnerFlg)
    }
  }
}
</script>

<style lang="scss" scoped>
.owners-list-table-body-row {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #{$light-grey};
  box-sizing: border-box;
  background-color: #{$white};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &.last {
    border: none;
  }
  &.even {
    background-color: #{$white_f7};
  }
  &.checkbox-mode {
    &:hover {
      opacity: 1;
      cursor: auto;
    }
  }
  .wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 12px;
    gap: 6px 5px;
  }
}
</style>
